import api from '../services/api';
import SocketClient from '../services/socket.io.client';
import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import moment from 'moment';


import { toastController } from '@ionic/vue';
import { createI18n } from 'vue-i18n';
import en from '../locales/en.json';
const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en,
  },
});
const { t: $t /*, tc: $tc*/ } = i18n.global;





const store = createStore({
  state () {
    return {
      lastPageName : '',
      user: {},
      Priviledges : [],
      bearerToken: null,
      generalParameters: {},
      drivingState: {},
      appDate: moment().format('YYYY-MM-DD'),
      printPage : true,
      InternetConnected : false,
      SocketConnected : false,
      googleMapsApiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      locale: 'en',
      vouchers: {},
      errorMessage:'',
      Drivers:[],
      alertPickups:[],
      alertTime : 20,
    }
  },
  getters: {
    isLoggedIn(store) {
      return Boolean(store.user && store.user.UserId);
    },
    lastPageName(state) { return state.lastPageName },
    user(state){ return state.user },
    Priviledges(state){ return state.Priviledges },

    //specialAdminPriv(state){ return (state.user?.Privileges?.findIndex('SPECIAL_ADMIN' ) || 0/*-1*/ )>-1 }, // true for now
  
    errorMessage(state){ return state.errorMessage },
    appDate(state){
      return state.appDate;
    },
    printPage(state){ return state.printPage; },
    InternetConnected (state) { return state.InternetConnected },
    SocketConnected (state) { return state.SocketConnected },
    Drivers(state){ return state.Drivers },
    alertPickups(state) { return state.alertPickups.filter(x=> { return moment().diff(moment(x.Time), 'seconds', true) < state.alertTime }) },
    alertTime(state) { return state.alertTime },

    PRIV_HISTORY_VIEW({Priviledges}){ return (Priviledges.find(x=>x.Module=='history')?.PriviledgeNr?.[0] || '0' )== '1' },
    PRIV_BOOK_ADD({Priviledges}){ return (Priviledges.find(x=>x.Module=='reservationexc')?.PriviledgeNr?.[1] || '0' )== '1' },
    PRIV_BOOK_VIEW({Priviledges}){ return (Priviledges.find(x=>x.Module=='reservationexc')?.PriviledgeNr?.[0] || '0' )== '1' },
    PRIV_EDIT_RESA_PRICE({Priviledges}){ return (Priviledges.find(x=>x.Module=='reservationexc')?.PriviledgeNr?.[13] || '0' )== '1' },
    PRIV_DRIVER_ASSIGNMENT({Priviledges}){ return (Priviledges.find(x=>x.Module=='bus')?.PriviledgeNr?.[4] || '0' )== '1' },
    //PRIV_PICKUP_ASSIGNMENT({Priviledges}){ return (Priviledges.find(x=>x.Module=='excdatebus')?.PriviledgeNr?.[4] || '0' )== '1' },
    PRIV_MAP_VIEW({Priviledges}){ return (Priviledges.find(x=>x.Module=='bus')?.PriviledgeNr?.[5] || '0' )== '1' },
    PRIV_GUIDE_ASSIGNMENT({Priviledges}){ return (Priviledges.find(x=>x.Module=='guide')?.PriviledgeNr?.[4] || '0' )== '1' },
    PRIV_SLSPEOPLE_PCK_VIEW({Priviledges}){ return (Priviledges.find(x=>x.Module=='salespeople_pickup')?.PriviledgeNr?.[0] || '0' )== '1' },
    PRIV_SLSPEOPLE_PCK_MNG({Priviledges}){ return (Priviledges.find(x=>x.Module=='salespeople_pickup')?.PriviledgeNr?.[1] || '0' )== '1' },
    PRIV_SLSPEOPLE_EXC_PRICE_VIEW({Priviledges}){ return (Priviledges.find(x=>x.Module=='excursion_pickup')?.PriviledgeNr?.[0] || '0' )== '1' },
    PRIV_SLSPEOPLE_EXC_PRICE_MNG({Priviledges}){ return (Priviledges.find(x=>x.Module=='excursion_pickup')?.PriviledgeNr?.[1] || '0' )== '1' },
    PRIV_PCK_TIME_EXC_VIEW({Priviledges}){ return (Priviledges.find(x=>x.Module=='pickup_time_expt')?.PriviledgeNr?.[0] || '0' )== '1' },
    PRIV_PCK_TIME_EXC_MNG({Priviledges}){ return (Priviledges.find(x=>x.Module=='pickup_time_expt')?.PriviledgeNr?.[1] || '0' )== '1' },
    PRIV_PCK_LIST_VIEW({Priviledges}){ return (Priviledges.find(x=>x.Module=='pickup_list')?.PriviledgeNr?.[0] || '0' )== '1' },
    PRIV_MORE_VIEW({Priviledges}){ return (Priviledges.find(x=>x.Module=='pickup_list')?.PriviledgeNr?.[1] || '0') == '1' },
    PRIV_DRIVER_ACTIVITY_VIEW({Priviledges}){ return (Priviledges.find(x=>x.Module=='report_bus_mov')?.PriviledgeNr?.[0] || '0' )== '1' },
    PRIV_GUIDE_ACTIVITY_VIEW({Priviledges}){ return (Priviledges.find(x=>x.Module=='report_bus_mov')?.PriviledgeNr?.[1] || '0' )== '1' },
    PRIV_STOP_SALE({Priviledges}){ return (Priviledges.find(x=>x.Module=='excdate')?.PriviledgeNr?.[5] || '0' )== '1' },
    PRIV_CASH_VIEW({Priviledges}){ return (Priviledges.find(x=>x.Module=='cash_movement')?.PriviledgeNr?.[0] || '0' )== '1' },
    PRIV_CASH_MNG({Priviledges}){ return (Priviledges.find(x=>x.Module=='cash_movement')?.PriviledgeNr?.[1] || '0' )== '1' },
    PRIV_CASH_MANY_DAYS({Priviledges}){ return (Priviledges.find(x=>x.Module=='cash_movement')?.PriviledgeNr?.[2] || '0' )== '1' },
    PRIV_SPECIAL_ADMIN({Priviledges}){ return (Priviledges.find(x=>x.Module=='ticket')?.PriviledgeNr?.[8] || '0' )== '1' },
    PRIV_SET_RESA_TICKET({Priviledges}){ return (Priviledges.find(x=>x.Module=='ticket')?.PriviledgeNr?.[9] || '0' )== '1' },
    PRIV_DELETE_RESA_SPECIAL({Priviledges}){ return (Priviledges.find(x=>x.Module=='reservationexc')?.PriviledgeNr?.[3] || '0' )== '1' },
    PRIV_PREV_EXC_DATES({Priviledges}){ return (Priviledges.find(x=>x.Module=='excdate')?.PriviledgeNr?.[8] || '0' )== '1' },
    //privAccess:store.getters.PRIV_CASH_MNG

  },
  mutations: {
    setErrorMessage(state, errorMessage){
      state.errorMessage = errorMessage
    },
    SET_USER(state, user){
      //console.log(user);
      if (user && user[0]) {
        state.user = user[0];
      }
    },
    SET_PRIVILEGES(state, Priviledges){ state.Priviledges = Priviledges },
    SET_GENERAL_PARAMETERS(state, generalParameters){
      state.generalParameters = generalParameters;
    },
    SET_BEARER_TOKEN(state, token){
      state.bearerToken = token;
    },
    SET_DRIVING_STATE(state, { excurs, ...data }){
      state.drivingState[excurs] = data;
    },
    RESET_BEARER_TOKEN(state){
      state.bearerToken = null;
    },
    SET_VOUCHER(state, voucher) {
      const { BookingID } = voucher;

      state.vouchers[BookingID] = voucher;
    },
    LOGOUT_USER(state) {
      state.user = null;
    },
    setLastPageName(state, lastPageName){ state.lastPageName = lastPageName },
    setAppDate(state, date){ state.appDate = date  },
    setPrintPage(state, print){ state.printPage = print  },
    setInternetConnected(state, conn) { state.InternetConnected = conn },
    setSocketConnected(state, conn) { state.SocketConnected = conn },
    setDrivers(state, drivers){ 
      state.Drivers = drivers
    },

    pushAlertPickups(state, newAlert){ 
      const arr = state.alertPickups.filter(x=> { return moment().diff(moment(x.Time), 'seconds', true) < state.alertTime })

      const index = arr.findIndex(x => 
        x.Pickup == newAlert.Pickup && 
        x.Excursion == newAlert.Excursion && 
        x.Date == newAlert.Date )
      if(index>-1) arr[index] = newAlert
      else arr.push(newAlert)

      state.alertPickups = arr
    }
  },
  actions: {
    // -----socket.io-------
    // MAP
    watchDriverPosition(state, callback) {
      return SocketClient.watchDriverPosition(callback)
    },
    //GENERAL
    watchMonitor() {
      return SocketClient.watchMonitor()
    },


    informAvailability(state, data) { console.log('informAvailability', data);
      return SocketClient.informAvailability(data)
    },

    onUpdatePax(state, callback) {
      //return SocketClient.onUpdatePax(callback)
      return SocketClient.onUpdatePax((msg)=> { 
        //if(msg.WAYPAY) return; // ingore waypay change
        callback(msg)
      })
    },
    onStopSale(state, callback) {
      return SocketClient.onStopSale(callback)
    },

    // -----------------------------------

    // watchMonitor(state, driver) {
    //   return SocketClient.watchMonitor(driver);
    // },

    // watchDriver(state, driver) {
    //   return SocketClient.watchDriver(driver);
    // },
    watchPickupChange(state, callback) {
      return SocketClient.watchPickupChange(callback);
    },
    watchDelay(state, callback) {
      return SocketClient.watchDelay(callback);
    },
    signalDriverPosition(state, data) {
      return SocketClient.signalDriverPosition(data);
    },
    watchExcursion(state, excurs) {
      return SocketClient.watchExcursion(excurs);
    },



    login({ commit }, data) {  
      return api.login(data).then(async (user) => {
        commit('SET_USER', user); console.log(user);
        await api.getUserPrivileges({ USERNAME:user[0].UserName }).then((data)=>{
          console.log('PRIN', data?.[0].Priviledges);
          //return data
          commit('SET_PRIVILEGES', data?.[0]?.Priviledges||[])
        })

      });
    },
    logout({ commit }) {
      document.cookie = 'sessionID=;path=/;Max-Age=-99999999;';
      commit('LOGOUT_USER');
      commit('SET_PRIVILEGES', [])

      window.location.replace('/');
    },
    getMonitorData(state, data){
      return api.getMonitorData(data)
    },
    stopSale(state, data){
      return api.stopSale(data).then((result)=>{
        SocketClient.informStopSales(data); 
        return result})
    },
    addPax({ dispatch }, data){
      return api.addPax(data).then((result) =>{
        console.log({
          ...data,
          ...result,
        });
        dispatch('informAvailability', {
          ...data,
          ...result,
        });
      })
    },
    getReservationPickups(state, data){
      return api.getReservationPickups(data)
    },
    getExcursions(state, data) {
      return api.getExcursions(data);
    },
    getExcursionsAutocomplete({ dispatch }, data) {
      return dispatch('getExcursions', data).then(({ data }) => data.map((excursion) => ({
        ...excursion,
        text: excursion.ExcursName,
        value: excursion.ExcursCode,
      })));
    },
    getHotelsAutocomplete(store, data) {
      return api.getHotels(data).then((data) => data.map((hotel) => ({
        ...hotel,
        text: hotel.Name,
        value: hotel.Code,
      })))
    },
    getDrivers({commit}, data) {
      return api.getDrivers(data).then((data)=>{ commit('setDrivers', data ); return data });
    },
    fetchReservationHistory(store, data) {
        return api.fetchReservationHistory(data);
    },
    fetchTicketHistory(store, data) {
        return api.fetchTicketHistory(data);
    },
    getDriversAutocomplete(store, data) {
      return api.getDrivers(data).then((data) => data.map((driver) => ({
        ...driver,
        text: driver.DriverName,
        value: driver.Driver,
      })));
    },
    getGuides(state, data) {
      return api.getGuides(data);
    },
    getGuidesAutocomplete(store, data) {
      return api.getGuides(data).then((guides) => guides.map((guide) => ({
        ...guide,
        text: guide.GuideName,
        value: guide.Guide,
      })));
    },
    getDriverActivity(state, data){
      return api.getDriverActivity(data);
    },
    getGuideActivity(state, data){
      return api.getGuideActivity(data);
    },
    getExcursionAssignment(state, data){
      return api.getExcdateBus(data);
    },
    assignExursion(state, data){
      return api.updateExcdateBus(data)
    },
    removeSignedDriver(state, data){
      return api.removeExcdateBus(data)
    },
    getPickupAssignment(state, data){
      return api.getExcdateBusPickup(data);
    },
    assignPickup(state, data){
      return api.updateExcdateBusPickup(data);
    },
    unassignPickup(state, data){
      return api.deleteExcdateBusPickup(data);
    },
    
    getCashMovements(state, data){
      return api.cash(data);
    },
    updateCashMovements(state, data){
      return api.updateCash(data);
    },
    removeCashMovements(state, data){
      return api.removeCash(data);
    },

    getPickupTimeExceptions(state, data){
      return api.excursionPickuptimeException(data);
    },
    updatePickupTimeException(state, data){
      return api.updateExcursionPickuptimeException(data);
    },
    removePickupTimeException(state, data){
      return api.removeExcursionPickuptimeException(data);
    },
    getSalesmanPickupException(state, data){
      return api.getSalespeopleExcursionPickup(data);
    },
    updateSalesmanPickupException(state, data){
      return api.updateSalespeopleExcursionPickup(data);
    },
    getSalesmanPriceException(state, data){
      return api.getSalespeopleExcursionPrice(data);
    },
    removeSalespersonPickupException(state, data){
      return api.removeSalespeopleExcursionPickup(data);
    },
    updateSalesmanPriceException(state, data){
      return api.updateSalespeopleExcursionPrice(data);
    },
    removeSalespersonPriceException(state, data){
      return api.removeSalespeopleExcursionPrice(data);
    },

    getSalespeople(state, data){
      return api.getSalespeople(data)
    },
    getSalespeopleAutocomplete({ dispatch }, data) {
      return dispatch('getSalespeople', data).then(({ data }) => data.map((salesman) => ({
        ...salesman,
        text: salesman.Name,
        value: salesman.Code,
      })));
    },
    getUsersAutocomplete(state, data) {
      return api.getUsers(data).then(({ data }) => data.map((user) => ({
        ...user,
        text: user.Name,
        value: user.Code,
      })));
    },

    getTouroperator(state, data){
      return api.getTouroperator(data)
    },
    getTouroperatorAutocomplete({ dispatch }, data) {
      return dispatch('getTouroperator', data).then(({ data }) => data.map((tourop) => ({
        ...tourop,
        text: tourop.Name,
        value: tourop.Code,
      })));
    },
    getAdvancedTicketReport(store, data) {
      return api.getAdvancedTicketReport(data)
    },
    getTicketEarnings(store, data) {
      return api.getTicketEarnings(data)
    },
    getCashReport(store, data) {
      return api.getCashReport(data)
    },

    getB2CSalesReport(store, data){
      return api.getB2CSalesReport(data)
    },
    
    getTicketTypes(state, data){
      return api.getTicketTypes(data)
    },

    getCurrencies(state, data){
      return api.getCurrencies(data)
    },
    getDefaultCurrency(state, data){
      return api.getDefaultCurrency(data)
    },
    
    getTicketTypesAutocomplete({ dispatch }, data){
      return dispatch('getTicketTypes', data).then(({ data }) => data.map((ticketType) => ({
        ...ticketType,
        text: ticketType.Name,
        value: ticketType.Code,
      })));
    },

    findReservation(state, data){
      return api.getExcateresa(data)
    },

    updateReservation({ dispatch }, { DRIVERS, GUIDE, DATE, TYPE, EXCURS, ...data }){
      return api
        .updateExcateresa(data)
        .then(({ data: result }) => {
          if (DRIVERS || GUIDE) {
              dispatch('informAvailability', {
                ...result,
                ...data,
                DRIVERS,
                GUIDE,
                DATE,
                TYPE,
                EXCURS,
              });
            }

            return result;
          })
    },

    cancelReservation(state, data){
      return api.cancelExcateresa(data)
    },

    reactivateReservation(state, data){
      return api.undoCancelExcateresa(data)
    },

    deleteReservation(state, data){
      return api.deleteExcateresa(data)
    },

    nextTicket(state, data){
      return api.nextTicket(data)
    },

    countTickets(state, data){
      return api.getTickets(data)
    },

    getDriversInExcurs(state, data){
      return api.getDriversInExcurs(data)
    },
    // allowPrint(state, data){
    //   return api.updateExcateresa(data)
    // },
    // getPickups(state, data) {
    //   return api.getExcursion(data).then(({ Pickups }) => Pickups.map((pickup) => ({
    //       ...pickup,
    //       text: `${pickup.Pickup} ${pickup.PickupTime}`,
    //       value: pickup.Id,
    //   })));
    // },

    // getPickups(state, data){
    //   return api.getPickups(data)
    // },
    // getExcursion(state, data) {
    //   return api.getExcursion(data);
    // },

  //   getGeneralParameters({ commit }) {
  //     return api.getGeneralParameters().then(([generalParameters]) => {
  //       commit('SET_GENERAL_PARAMETERS', generalParameters);
  //     });
  //   },


  //   setDrivingState({ commit }, data) {
  //     return commit('SET_DRIVING_STATE', data);
  //   },

  getReservations(state, data) {
    return api.getReservations(data);
  },

  getExcursion(state, data) {
    return api.getExcursion(data);
  },
  getPickups(state, data) {
    return api.getExcursion(data).then(({ Pickups }) => Pickups.map((pickup) => ({
      ...pickup,
      text: `${pickup.Pickup} ${pickup.PickupTime}`,
      value: pickup.Id,
    })));
  },
  getReservation(state, data) {
    return api.getReservation(data).then(([booking]) => ({
      ...booking,
      Excursions: booking.Excursions.map((reservation) => ({
        ...reservation,
        TicketCombined: `${reservation.TicketOrder}${reservation.TicketNumber}`,
      })),
    }));
  },
  async storeVoucher({ commit, dispatch }, data) {
    const barcodesPromise = data.Excursions.map(({ BookingNumber }) => dispatch('getBarcode', BookingNumber)
      .then((data) => Buffer.from(data, 'binary').toString('base64')));

    await Promise.all(barcodesPromise).then((barcodes) => {
      data.Excursions = data.Excursions.map((excursion, i) => ({
        ...excursion,
        barcode: barcodes[i],
      }));
    });

    return commit('SET_VOUCHER', data);
  },
  fetchWaypays(state, data) {
    return api.fetchWaypays(data);
  },
  fetchPickupExceptions(state, data) {
    return api.fetchPickupExceptions(data);
  },
  fetchPrice(state, data) {
    return api.fetchPrice(data);
  },
  bookReservation({ dispatch }, data) {
    return api.bookReservation(data).then((result) => {
      return dispatch('getReservation', {
        RESERVATIONS: [result.id],
      }).then(async (booking) => {
        if (booking?.Excursions[0]?.Driver) {
          dispatch('informAvailability', {
            ...data,
            ...result,
            DRIVER: booking.Excursions[0].Driver,
          });

          return Promise.resolve(result);
        }
        
        return api.getDriversInExcurs(data.EXCURSIONS[0]).then((drivers) => {
          dispatch('informAvailability', {
            ...data,
            ...result,
            DRIVERS: drivers.map(({ DriverCode }) => DriverCode),
          });
        });
      }).then(() => result);
    }).catch(({ response }) => {
      if (response) {
        throw new Error(response.data);
      }

      toastController
        .create({
          message: $t('errorCodes.something-went-wrong'),
          color: 'danger',
          duration: 4000,
        }).then((toast) => toast.present());
    });
  },
  editReservations({ dispatch }, { data, resa }) {
    return api.updateExcateresa(data).then(async (result) => {

      let updatedResa = {}
      let DRIVERS = []
      const AdminMsg = []

      if(data.EXCDATE){
        updatedResa = await dispatch('findReservation', {
          BOOKNR: [resa.BookingNumber]
        }).then(({data})=>data[0].Excursions[0]).catch((e)=>console.log(e))

        // if it moves to today or change today excursion 
        if(moment(updatedResa.Date).isSame(moment(), 'd') ||
          (moment(resa.Date).isSame(moment(), 'd') && resa.ExcursCode != updatedResa.ExcursCode )
        ){
          if(updatedResa.Driver){
            DRIVERS.push(updatedResa.Driver)
          }
          else {
            DRIVERS = await api.getDriversInExcurs({
              EXCDATE:updatedResa.ExcDate,
              EXCURS:updatedResa.ExcursCode,
              DATE:moment(updatedResa.Date).format('YYYY-MM-DD')
            }).then((drivers) => {
              return drivers.map(({ DriverCode }) => DriverCode)
            });      
          }
        }
      }
// console.log('data.EXCDATE', resa.PickupCode,' != ',data.PICKUP, resa.Adults,' != ',data.PAX1,'  ||  ',
// resa.Children,' != ',data.PAX2,'  ||  ',
// resa.Infants,' != ',data.PAX5,'  ||  ',
// resa.OtherAdults,' != ',data.PAX4,'  ||  ',
// resa.OtherChildren,' != ',data.PAX3, data.EXCDATE);
      if(resa.PickupCode != data.PICKUP ||
         resa.Adults != data.PAX1 || 
         resa.Children != data.PAX2 || 
         resa.Infants != data.PAX5 || 
         (typeof data.PAX4 != 'undefined' && resa.OtherAdults != data.PAX4) || 
         (typeof data.PAX3 != 'undefined' && resa.OtherChildren != data.PAX3) || data.EXCDATE ){

          AdminMsg.push({
            DATE  :resa.Date,
            EXCURS:resa.ExcursCode,
            PICKUP:resa.PickupCode
          })
          if(data.EXCDATE)
          AdminMsg.push({
            DATE  :updatedResa.Date,
            EXCURS:updatedResa.ExcursCode,
            PICKUP:updatedResa.PickupCode
          })
      //}

      if (data.Driver) {
        DRIVERS.push(data.Driver)

        await dispatch('informAvailability', {
          //...data,
          EXCURSIONS:AdminMsg,//[data, updatedResa],
          ...result,
          //DRIVER: data.Driver,
          DRIVERS:[...new Set(DRIVERS)]
        });

        return Promise.resolve(result);
      }

      return api.getDriversInExcurs({
        EXCDATE:resa.ExcDate,
        EXCURS:resa.ExcursCode,
        DATE:moment(resa.Date).format('YYYY-MM-DD')
      }).then((drivers) => {
        dispatch('informAvailability', {
          EXCURSIONS:AdminMsg,//[resa, updatedResa],
          ...result,
          DRIVERS: [...new Set(drivers.map(({ DriverCode }) => DriverCode).concat(DRIVERS))],
        });

        return result;
      });
    }

    })
    .catch(({ response }) => {
      if (response) {
        throw new Error(response.data);
      }

      toastController
        .create({
          message: $t('errorCodes.something-went-wrong'),
          color: 'danger',
          duration: 4000,
        }).then((toast) => toast.present());
    });
  },

    setBearerToken({ commit }, token) {
      return commit('SET_BEARER_TOKEN', token);
    },
    resetBearerToken({ commit }, token) {
      return commit('RESET_BEARER_TOKEN', token);
    },
  },
  plugins: [createPersistedState()],
});

export default store;